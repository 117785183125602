<template>
  <div id="home">
    <preloadComp />
    <headComp />
    <div class="d-flex align-items-stretch h-100">
      <menuComp />
      <div class="page-content" :class="{ active: showSidebar }">
        <shopComp />
        <footerComp />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import preloadComp from '@/components/preloaderComponent.vue';
import headComp from '@/components/headComponent.vue';
import menuComp from '@/components/menuComponent.vue';
import shopComp from '@/components/shopComponent.vue';
//import shopComp from '@/components/subscribtionCalc.vue';
import footerComp from '@/components/footerComponent.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'home',
  components: {
    preloadComp,
    headComp,
    menuComp,
    shopComp,
    footerComp,
  },
  computed: {
    ...mapGetters(['userInfo', 'showSidebar']),
  },
  methods: {
    ...mapActions(['getUserInfo', 'checkAuth']),
  },
  async mounted() {
    if ((await this.checkAuth()) == 'no_login') {
      this.$router.push('/auth');
    }
  },
};
</script>
