import Vue from 'vue';
import Router from 'vue-router';
import Auth from './views/Authorization.vue';
import Download from './views/Download.vue';
import Faq from './views/Faq.vue';
import Home from './views/Home.vue';
import Shop from './views/Shop.vue';
import Update from './views/Update.vue';
Vue.use(Router);

export default new Router({
  hashbang: false,
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/shop',
      name: 'shop',
      component: Shop,
    },
    {
      path: '/faq',
      name: 'faq',
      component: Faq,
    },
    {
      path: '/downloads',
      name: 'downloads',
      component: Download,
    },
    {
      path: '/auth',
      name: 'auth',
      component: Auth,
    },
    {
      path: '/register',
      name: 'register',
      component: Auth,
    },
    {
      path: '/recover',
      name: 'recover',
      component: Auth,
    },
    {
      path: '/update',
      name: 'update',
      component: Update,
    },
  ],
});
