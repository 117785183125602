<template>
  <div id="authComp">
    <div class="text-center">
      <img
        src="img/logo.png"
        alt="..."
        style="max-width: 18rem"
        class="img-fluid mb-4"
      />
      <h3 class="text-gray-light mb-4">Авторизация</h3>
    </div>

    <div class="form-group">
      <label>Email или логин</label>
      <input
        v-validate="'required|min:3|max:16|alpha_num'"
        type="text"
        placeholder="name@domain.ru"
        autocomplete="off"
        required
        data-msg="Пожалуйста введите ваш  email"
        class="form-control"
        v-model="login"
        :text="reset_button"
        v-on:keyup.enter="on_wait_login"
        tabindex="1"
      />
    </div>
    <div class="form-group mb-4">
      <div class="row">
        <div class="col">
          <label>Пароль</label>
        </div>
        <!--<small class="text-muted text-center"><a href="#" @click="$router.push('/register')">Забыли пароль?</a> </small> -->

        <div class="col-auto">
          <a
            href="#"
            class="form-text small text-muted"
            @click="$router.push('/recover')"
            tabindex="4"
            >Забыли пароль?</a
          >
        </div>
      </div>
      <input
        placeholder="Password"
        type="password"
        required
        data-msg="Пожалуйста введите ваш пароль"
        class="form-control"
        v-model="password"
        :text="reset_button"
        v-on:keyup.enter="on_wait_login"
        tabindex="2"
      />
    </div>
    <!-- Submit-->
    <button
      type="button"
      class="btn btn-lg btn-block btn-primary mb-3"
      @click="on_wait_login"
      tabindex="3"
      :disabled="logining"
    >
      Авторизоваться
    </button>
    <!-- Link-->
    <p class="text-center">
      <small class="text-muted text-center"
        >Еще нету аккаунта?
        <a href="#" @click="$router.push('/register')">Зарегистрировать</a
        >.</small
      >
    </p>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      recaptchaToken: null,
      login: '',
      password: '',
      logining: false,
    };
  },
  methods: {
    ...mapActions(['login_func', 'reset_base']),
    async on_login() {
      let response = await this.login_func({
        login: this.login,
        password: this.password,
        'g-recaptcha-response': this.recaptchaToken,
      });

      this.password = '';
      this.logining = false;

      if (response.status === 'success') {
        Messenger().post({
          message: 'Успешная авторизация, переадресация!',
          type: 'success',
          hideAfter: 0.8,
        });

        this.reset_base();
        return setTimeout(() => {
          this.$router.push('/');
          this.logining = false;
        }, 500);
      }

      if (response.status == 'error') {
        switch (response.error_code) {
          case 'no_captcha': {
            return Messenger().post({
              message: 'Капча разгадана неверно, попробуйте снова!',
              type: 'error',
              hideAfter: 1.8,
            });
          }
          case 'password_expiried': {
            return Messenger().post({
              message:
                'Вы попытались авторизоваться используя временный пароль, но он истёк!',
              type: 'error',
              hideAfter: 1.8,
            });
          }
          case 'no_login': {
            return Messenger().post({
              message: 'Ошибка авторизации!',
              type: 'error',
              hideAfter: 1.8,
            });
          }
          case 'no_valid': {
            return Messenger().post({
              message: 'Неверный логин или пароль!',
              type: 'error',
              hideAfter: 1.8,
            });
          }
        }
      }

      return Messenger().post({
        message: 'Неизвестная ошибка!',
        type: 'error',
        hideAfter: 0.8,
      });
    },
    on_wait_login() {
      if (this.login.length < 3 || this.password.length < 6) {
        return Messenger().post({
          message: 'Заполните поля логин и пароль',
          type: 'info',
          hideAfter: 1.2,
        });
      }

      this.logining = true;

      this.on_login();
    },
    reset_button() {
      this.logining = false;
    },
  },
};
</script>
