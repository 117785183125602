<template>
  <div id="shopCopm">
    <div
      style="
        width: 100%;
        height: 100%;
        background-color: #34373d;
        position: absolute;
        z-index: 99;
        opacity: 0.9;
      "
      v-if="!userInfo.activated.active"
    ></div>
    <div class="page-header">
      <div class="container-fluid">
        <h2 class="h5 no-margin-bottom">Магазин</h2>
      </div>
    </div>

    <div class="container-fluid">
      <ul class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#" @click="$router.push('/')">Профиль</a>
        </li>
        <li class="breadcrumb-item active">Магазин</li>
      </ul>
    </div>

    <section>
      <div class="container">
        <div class="row">
          <div
            class="col-sm-6 col-md-6 col-lg-4 shop-card"
            v-for="card in price_card"
            :key="card.id"
          >
            <div
              v-if="discountTariff(card) != 0"
              class="price-block"
              style="z-index: 99"
            >
              -{{ discountTariff(card) }}%
            </div>
            <div class="card">
              <div
                class="card-status"
                :class="{ 'bg-primary': userInfo.sub_level == 1 }"
              ></div>
              <div class="card-body text-center py-5">
                <div class="card-category">{{ card.name }}</div>
                <div class="display-3 mt-5 text-light">
                  ₽{{ global.shop[card.id].price }}
                </div>
                <div
                  v-if="global.shop[card.id].old_price != 0"
                  class="display-6 mb-4"
                  style="font-size: 12"
                >
                  ₽
                  <label class="striked up">{{
                    global.shop[card.id].old_price
                  }}</label>
                </div>
                <div
                  v-if="global.shop[card.id].old_price == 0"
                  class="display-6 mb-4"
                  style="font-size: 12"
                >
                  <label style="position: relative">&nbsp;</label>
                </div>
                <ul class="text-small list-unstyled lh-2 mb-5">
                  <div align="left">
                    <li v-for="option in card.options" :key="option.id">
                      <i
                        aria-hidden="true"
                        class="fa fa-check text-success mr-2"
                      ></i
                      >{{ option.text }}
                    </li>
                  </div>
                </ul>
                <div class="text-center">
                  <a
                    :href="global.shop[card.id].link || '#'"
                    target="_blank"
                    class="btn btn-primary px-4"
                    >Купить</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'shopComp',
  computed: {
    ...mapGetters(['userInfo', 'global']),
  },
  methods: {
    discountTariff() {
      return 0;
    },
  },
  data() {
    return {
      price_card: [
        {
          id: 0,
          name: 'NANO SUB [1 ДЕНЬ]',
          options: [
            { id: 0, text: ' Полный пакет скриптов' },
            { id: 1, text: ' Бесплатные обновления' },
            { id: 2, text: ' 3 отвязки аккаунта в неделю' },
            { id: 3, text: ' Техническая поддержка' },
          ],
        },
        {
          id: 1,
          name: 'LITE SUB [1 НЕДЕЛЯ]',
          options: [
            { id: 0, text: ' Полный пакет скриптов' },
            { id: 1, text: ' Бесплатные обновления' },
            { id: 2, text: ' 3 отвязки аккаунта в неделю' },
            { id: 3, text: ' Техническая поддержка' },
          ],
        },
        {
          id: 2,
          name: 'PRO [1 МЕСЯЦ]',
          options: [
            { id: 0, text: ' Полный пакет скриптов' },
            { id: 1, text: ' Бесплатные обновления' },
            { id: 2, text: ' 3 отвязки аккаунта в неделю' },
            { id: 3, text: ' Техническая поддержка' },
          ],
        },
        {
          id: 3,
          name: 'PRO+ [3 МЕСЯЦА]',
          options: [
            { id: 0, text: ' Полный пакет скриптов' },
            { id: 1, text: ' Бесплатные обновления' },
            { id: 2, text: ' 9 отвязок аккаунта в неделю' },
            { id: 3, text: ' Техническая поддержка' },
          ],
        },
        {
          id: 4,
          name: 'VIP [6 МЕСЯЦЕВ]',
          options: [
            { id: 0, text: ' Полный пакет скриптов' },
            { id: 1, text: ' Бесплатные обновления' },
            { id: 2, text: ' 15 отвязок аккаунта в неделю' },
            { id: 3, text: ' Техническая поддержка' },
          ],
        },
      ],
    };
  },
};
</script>
