<template>
  <div id="app">
    <transition>
      <router-view />
    </transition>
  </div>
</template>

<style></style>

<script>
export default {
  name: 'app',
  data() {
    return {};
  },
};
</script>
