<template>
  <div class="home">
    <preloadComp />
    <headComp />
    <div class="d-flex align-items-stretch h-100">
      <menuComp />
      <div class="page-content" :class="{ active: showSidebar }">
        <div id="profileComp">
          <profileComp />
          <footerComp />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import preloadComp from '@/components/preloaderComponent.vue';
import headComp from '@/components/headComponent.vue';
import menuComp from '@/components/menuComponent.vue';
import profileComp from '@/components/profileComponent.vue';
import footerComp from '@/components/footerComponent.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'home',
  components: {
    preloadComp,
    headComp,
    menuComp,
    profileComp,
    footerComp
  },
  computed: {
    ...mapGetters(['userInfo', 'showSidebar'])
  },
  methods: {
    ...mapActions(['getUserInfo', 'checkAuth', 'init_daemon'])
  },
  async mounted() {
    this.init_daemon();
    var a = await this.checkAuth();
    if (a == 'no_login') {
      this.$router.push('/auth');
    }
  }
};
</script>
