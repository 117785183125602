<template>
  <div id="preloader" v-if="!userInfo.loaded">
    <div
      class="d-flex align-items-center justify-content-center"
      style="width: 100%; height: 100%; background-color: #34373d; "
    >
      <div class="card-body d-flex justify-content-center">
        <div style="flex-direction: column;">
          <div class="text-center">
            <img src="img/logo.jpg" class="card-profile-img" />
          </div>
          <div class="d-flex justify-content-center mt-4">
            <div class="sk-folding-cube">
              <div class="sk-cube1 sk-cube"></div>
              <div class="sk-cube2 sk-cube"></div>
              <div class="sk-cube4 sk-cube"></div>
              <div class="sk-cube3 sk-cube"></div>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <strong>Идёт загрузка</strong
            ><strong style="width: 12px">{{ textPoint }}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass">
#preloader
  width: 100vw
  height: 100vh
  z-index: 1
</style>
<script>
import { setInterval } from 'timers';
import { mapGetters } from 'vuex';

export default {
  name: 'preloader',
  data() {
    return {
      textPoint: ''
    };
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {},
  mounted() {
    const self = this;
    setInterval(function() {
      self.textPoint = self.textPoint.length < 3 ? self.textPoint + '.' : '';
    }, 500);
  }
};
</script>
