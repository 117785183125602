<template>
  <footer class="footer">
    <div class="footer__block block no-margin-bottom">
      <div class="container-fluid text-center">
        <p class="no-margin-bottom">2018-{{getYear}} &copy; D2JS:Reload.</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    getYear() {
      const now = new Date();
      return now.getFullYear()
    }
  }
}
</script>
