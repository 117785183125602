<template>
  <div
    id="passModal"
    tabindex="-1"
    ref="passModal"
    role="dialog"
    class="modal fade text-left"
    style="display: none"
    aria-hidden="true"
  >
    <div role="document" class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <strong class="modal-title">Изменить пароль</strong>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            class="close"
            @click="closeModal"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <input
                v-validate="'required|min:6|max:32|'"
                name="old_password"
                type="password"
                placeholder="Введите старый пароль"
                class="form-control"
                v-model.trim="old_password"
                ref="password"
              />
              <div
                class="d-flex flex-row align-self-center mt-2"
                v-if="errors.has('old_password')"
              >
                <i
                  v-show="errors.has('old_password')"
                  class="fa fa-warning error-icon"
                ></i>
                <span
                  v-show="errors.has('old_password')"
                  class="help is-danger error-text"
                  >&nbsp; {{ errors.first('old_password') }}</span
                >
              </div>
            </div>

            <div class="form-group">
              <input
                v-validate="'required|min:6|max:32|'"
                name="password"
                type="password"
                placeholder="Введите новый пароль"
                class="form-control"
                v-model.trim="password"
                ref="password"
              />
              <div
                class="d-flex flex-row align-self-center mt-2"
                v-if="errors.has('password')"
              >
                <i
                  v-show="errors.has('password')"
                  class="fa fa-warning error-icon"
                ></i>
                <span
                  v-show="errors.has('password')"
                  class="help is-danger error-text"
                  >&nbsp; {{ errors.first('password') }}</span
                >
              </div>
              <!--<input placeholder="Новый пароль" type="password" class="form-control" v-model.trim="$v.password.$model" :class="{ 'is-invalid': $v.password.$error }">
                            <div class="is-invalid invalid-feedback" v-if="!$v.password.required">Заполните это поле.</div>
                            <div class="is-invalid invalid-feedback" v-if="!$v.password.minLength">Пароль должен содержать {{ $v.password.$params.minLength.min }} символов.</div>-->
            </div>

            <div class="form-group">
              <input
                v-validate="'required|confirmed:password'"
                name="password_confirmation"
                type="password"
                placeholder="Повторите пароль"
                class="form-control"
                data-vv-as="password"
                v-model.trim="password_confirmation"
              />
              <div
                class="d-flex flex-row align-self-center mt-2"
                v-if="errors.has('password_confirmation')"
              >
                <i
                  v-show="errors.has('password_confirmation')"
                  class="fa fa-warning error-icon"
                ></i>
                <span
                  v-show="errors.has('password_confirmation')"
                  class="help is-danger error-text"
                  >&nbsp; {{ errors.first('password_confirmation') }}</span
                >
              </div>
              <!--<input placeholder="Подтвердите пароль" type="password" class="form-control" v-model.trim="$v.repeatPassword.$model" :class="{ 'is-invalid': $v.repeatPassword.$error }">
                            <div class="is-invalid invalid-feedback" v-if="!$v.repeatPassword.sameAsPassword">Пароли не совпадают</div>-->
            </div>

            <!--
                            <div class="form-group">
                                <input id="acceptChangeMail" type="checkbox" v-model="accept" class="checkbox-template" />
                                <label for="acceptChangeMail">Вы уверены что хотите изменить пароль?</label>
                            </div>
                        -->
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" class="btn btn-secondary">
            Отменить
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="on_change"
            data-dismiss="modal"
          >
            Изменить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*global Messenger*/
/*eslint no-undef: "error"*/

import { mapActions } from 'vuex';

export default {
  data() {
    return {
      old_password: '',
      password: '',
      password_confirmation: '',
    };
  },
  methods: {
    ...mapActions(['change_password']),
    closeModal: function () {
      this.password = '';
      this.password_confirmation = '';
      this.old_password = '';
    },
    async changePassword() {
      let response = await this.change_password({
        old_password: this.old_password,
        password: this.password,
      });
      if (response.status === 'error') {
        if (response.error_code) {
          Messenger().post({
            message: 'Неверный текущий пароль!',
            type: 'error',
            hideAfter: 0.8,
          });
        } else {
          Messenger().post({
            message: 'Неизвестная ошибка!',
            type: 'error',
            hideAfter: 0.8,
          });
        }
      } else {
        if (response.status == 'success')
          Messenger().post({
            message: 'Пароль успешно изменен!',
            type: 'success',
            hideAfter: 0.8,
          });
      }
      this.closeModal();
    },
    async on_change() {
      this.$validator.validateAll().then((result) => {
        if (result) this.changePassword();
      });
    },
  },
  mounted() {
    $(this.$refs.passModal).on('hidden.bs.modal', this.closeModal);
  },
};
</script>
