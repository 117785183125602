<template>
  <nav id="sidebar" :class="{ shrinked: showSidebar }">
    <div
      class="sidebar-header"
      :class="{ 'd-flex': !showSidebar, 'align-items-center': !showSidebar }"
      id="nav-avatar"
    >
      <div class="avatar">
        <img
          :src="userInfo.steam.avatar || 'img/default-avatar.png'"
          alt="..."
          class="img-fluid rounded-circle"
        />
      </div>
      <div class="title">
        <h1
          class="h6"
          id="SteamLogin"
          style="text-align: center; font-size: 15px"
        >
          {{ userInfo.login }}
        </h1>
        <p>
          <a href="#">
            <strong class="text-primary" style="font-size: 12px">{{
              sub_level
            }}</strong>
          </a>
        </p>
      </div>
    </div>
    <span class="heading">Главное меню</span>
    <ul class="list-unstyled">
      <li :class="{ active: $route.name === 'home' }">
        <a href="#" @click="$router.push('/')">
          <i class="icon-home"></i>Профиль
        </a>
      </li>
      <!--  <li :class="{ active: $route.name === 'shop' }">
        <a href="#" @click="$router.push('/shop')">
          <i class="fa fa-shopping-cart"></i>Магазин
        </a>
      </li> -->

      <li :class="{ active: $route.name === 'downloads' }">
        <a href="#dashlist" aria-expanded="true" data-toggle="collapse">
          <i class="fa fa-question" />FAQ
        </a>
        <ul
          id="dashlist"
          :class="
            ($route.name === 'downloads' ? 'show' : '') +
            'collapse list-unstyled'
          "
        >
          <li>
            <a href="#" @click="$router.push('/downloads')">Центр загрузок</a>
          </li>
          <li>
            <a href="https://vk.com/@dota2reload-kak-ustanovit-chit" target="_blank"
              >Подробная инструкция по установке</a
            >
          </li>
          <!--<li><a href="#">Описание функционала</a></li>-->
        </ul>
      </li>
      <li class>
        <a href="https://vk.me/dota2reload" target="_blank">
          <i class="fa fa-vk"></i>Тех. поддержка
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'menuComp',
  computed: {
    ...mapGetters(['userInfo', 'sub_level', 'showSidebar']),
  },
};
</script>
