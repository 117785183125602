<template>
  <div class="faq">
    <preloadComp />
    <headComp />
    <div class="d-flex align-items-stretch h-100">
      <menuComp />
      <div class="page-content" :class="{ active: showSidebar }">
        <div class="download">
          <downloadComp />
          <footerComp />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import preloadComp from '@/components/preloaderComponent.vue';
import headComp from '@/components/headComponent.vue';
import menuComp from '@/components/menuComponent.vue';
import downloadComp from '@/components/downloadComponent.vue';
import footerComp from '@/components/footerComponent.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'faq',
  components: {
    preloadComp,
    headComp,
    menuComp,
    downloadComp,
    footerComp
  },
  computed: {
    ...mapGetters(['userInfo', 'showSidebar'])
  },
  methods: {
    ...mapActions(['getUserInfo', 'checkAuth'])
  },
  async mounted() {
    if ((await this.checkAuth()) == 'no_login') {
      this.$router.push('/auth');
    }
  }
};
</script>
