<template>
  <div id="regComp">
    <div class="text-center">
      <img
        src="img/logo.png"
        alt="..."
        style="max-width: 18rem"
        class="img-fluid mb-4"
      />
      <h3 class="text-gray-light mb-4">Регистрация</h3>
    </div>

    <div class="form-group">
      <label>Логин</label>
      <input
        name="login"
        v-validate="'required|min:3|max:16|alpha_num'"
        v-model="login"
        type="text"
        placeholder="Введите логин"
        class="form-control"
        :class="{ 'is-danger': errors.has('login') }"
      />
      <div
        class="d-flex flex-row align-self-center mt-2"
        v-if="errors.has('login')"
      >
        <i v-show="errors.has('login')" class="fa fa-warning error-icon"></i>
        <span v-show="errors.has('login')" class="help is-danger error-text"
          >&nbsp; {{ errors.first('login') }}</span
        >
      </div>
    </div>

    <div class="form-group">
      <label>Email</label>
      <input
        name="email"
        placeholder="Введите email"
        class="form-control"
        v-model.trim="email"
        v-validate="'required|email'"
        type="text"
      />
      <div
        class="d-flex flex-row align-self-center mt-2"
        v-if="errors.has('email')"
      >
        <i v-show="errors.has('email')" class="fa fa-warning error-icon"></i>
        <span v-show="errors.has('email')" class="help is-danger error-text"
          >&nbsp; {{ errors.first('email') }}</span
        >
      </div>
    </div>
    <div class="form-group mb-4">
      <label>Пароль</label>
      <input
        v-validate="'required|min:6|max:32|'"
        name="password"
        type="password"
        placeholder="Введите пароль"
        class="form-control"
        v-model.trim="password"
        ref="password"
      />
      <div
        class="d-flex flex-row align-self-center mt-2"
        v-if="errors.has('password')"
      >
        <i v-show="errors.has('password')" class="fa fa-warning error-icon"></i>
        <span v-show="errors.has('password')" class="help is-danger error-text"
          >&nbsp; {{ errors.first('password') }}</span
        >
      </div>
    </div>

    <div class="form-group mb-4">
      <label>Подтверждение пароля</label>
      <input
        v-validate="'required|confirmed:password'"
        name="password_confirmation"
        type="password"
        placeholder="Повторите пароль"
        class="form-control"
        data-vv-as="password"
        v-model.trim="repassword"
      />
      <div
        class="d-flex flex-row align-self-center mt-2"
        v-if="errors.has('password_confirmation')"
      >
        <i
          v-show="errors.has('password_confirmation')"
          class="fa fa-warning error-icon"
        ></i>
        <span
          v-show="errors.has('password_confirmation')"
          class="help is-danger error-text"
          >&nbsp; {{ errors.first('password_confirmation') }}</span
        >
      </div>
    </div>
    <div class="form-group terms-conditions mb-4">
      <input
        id="register-agree"
        name="terms"
        v-validate="'required'"
        type="checkbox"
        class="checkbox-input checkbox-template"
      />
      <label for="register-agree"
        >Я принимаю <a href="/terms.pdf" target="_blank">условия пользования</a>
      </label>
      <div
        class="d-flex flex-row align-self-center mt-2"
        v-if="errors.has('terms')"
      >
        <i v-show="errors.has('terms')" class="fa fa-warning error-icon"></i>
        <span v-show="errors.has('terms')" class="help is-danger error-text"
          >&nbsp; {{ errors.first('terms') }}</span
        >
      </div>
    </div>

    <!-- Submit-->
    <button
      type="button"
      class="btn btn-lg btn-block btn-primary mb-3"
      @click="on_validate"
      :disabled="processing"
    >
      Зарегистрироваться
    </button>
    <!-- Link-->
    <p class="text-center">
      <small class="text-muted text-center"
        >Уже зарегистрированы?
        <a href="#" @click="$router.push('/auth')">Войти</a>.</small
      >
    </p>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

/*import russian from 'vee-validate/dist/locale/ru';
this.$validator.localize('ru');*/

export default {
  data() {
    return {
      login: '',
      email: '',
      password: '',
      repassword: '',
      recaptchaToken: null,
      rules: false,
      processing: false,
    };
  },
  methods: {
    ...mapActions(['reg_func']),
    async reg_on() {
      let response = await this.reg_func({
        login: this.login,
        password: this.password,
        email: this.email,
        'g-recaptcha-response': this.recaptchaToken,
      });
      if (response.status == 'error') {
        this.processing = false;
        if (response.error_code == 11000) {
          Messenger().post({
            message: 'Аккаунт с этими email или login уже существует!',
            type: 'error',
          });
          return;
        }
        if (response.error_code == 'no_captcha') {
          Messenger().post({
            message: 'Ошибка решения капчи!',
            type: 'error',
          });
          return;
        }

        Messenger().post({
          message: 'Неизвестная ошибка!',
          type: 'error',
        });
      } else {
        if (response.status == 'success') {
          Messenger().post({
            message: 'Успешная регистрация, переадресация в личный кабинет!',
            type: 'success',
            hideAfter: 0.8,
          });
          setTimeout(() => {
            this.$router.push('/');
            this.processing = false;
          }, 500);
        } else {
          if (response == null || response.status === 'error') {

            Messenger().post({
              message: 'Неизвестная ошибка!',
              type: 'error',
            });

            this.password = '';
            this.repassword = '';
            this.processing = false;
          }
        }
      }
    },
    on_wait_reg() {
      this.processing = true;

      this.reg_on();
    },

    on_validate() {
      this.$validator.validateAll().then((result) => {
        if (result) this.on_wait_reg();
      });
    },
  },
  components: {},
};
</script>
