<template>
  <div
    id="emailModal"
    tabindex="-1"
    role="dialog"
    class="modal fade text-left"
    style="display: none"
    aria-hidden="true"
  >
    <div role="document" class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <strong class="modal-title">Изменить eMail</strong>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            class="close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="form-validate">
            <div class="form-group">
              <input
                type="email"
                placeholder="example@email.com"
                autocomplete="off"
                class="form-control"
                required
                data-msg="Пожалуйста введите ваш  email"
              />
            </div>
            <div class="form-group">
              <input
                id="acceptChangeMail"
                type="checkbox"
                class="checkbox-template"
              />
              <label for="acceptChangeMail"
                >Вы уверены что хотите изменить почту?</label
              >
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" class="btn btn-secondary">
            Отменить
          </button>
          <button type="button" class="btn btn-primary">Изменить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
